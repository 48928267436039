<template>
    <div class="nav-bar-operations">
        <img src="@/assets/logogruas.png" alt="HomePage" @click="backMainPanel" class="logo-button" />
    </div>


<div style="padding-top: 30px;"></div>

    <div>
    <!-- Tab Navigation -->
    <ul class="nav nav-tabs" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'tab1' }"
          @click="activeTab = 'tab1'"
          href="#"
        >
          Asistencia Vial
        </a>
      </li>

      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'tab3' }"
          @click="activeTab = 'tab3'"
          href="#"
        >
          Auxilio Vial
        </a>
      </li>
      
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: activeTab === 'tab2' }"
          @click="activeTab = 'tab2'"
          href="#"
        >
          Montacargas
        </a>
      </li>
    </ul>


    <!-- Tab Content -->
    <div class="tab-content mt-3">
      <div
        class="tab-pane"
        :class="{ active: activeTab === 'tab1' }"
        v-if="activeTab === 'tab1'"
      >
        
      <div id="ContentForAuxAssistance">
        <div class="text-operations">
        <h2>Órdenes de trabajo</h2>
        <div class="thermometer" @mouseleave="hideStatusCount">
            <div class="bar active" :style="{ width: (this.statusCounts['Activo'] / (this.n_inProcessOrders+this.n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCount('Activo', $event)"></div>
            <div class="bar cancelled" :style="{ width: (this.statusCounts['Cancelado'] / (this.n_inProcessOrders+this.n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCount('Cancelado', $event)"></div>
            <div class="bar scheduled" :style="{ width: (this.statusCounts['Programado'] / (this.n_inProcessOrders+this.n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCount('Programado', $event)"></div>
                <div class="bar assigned" :style="{ width: (this.statusCounts['Asignada'] / (this.n_inProcessOrders+this.n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCount('Asignada', $event)"></div>
            <div class="bar completed" :style="{ width: (this.statusCounts['Completado'] / (this.n_inProcessOrders+this.n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCount('Completado', $event)"></div>
        </div>
    </div>
    <p v-if="hoverStatusCount" :style="hoverStatusCountStyle" class="status-count-bubble">{{ hoverStatusCount }}
    </p>
    <div class="action-buttons">
        <button @click="globalfilters" class="action-button">Filtros globales</button>
        <button @click="statusUnits" class="action-button">Ver estatus de unidades</button>
        <button @click="pricesRequests" class="action-button">Ver cotizaciones</button>
        <button @click="newOrder" class="action-button">Nueva orden</button>
        <button @click="rastreoUnidades" class="action-button">Rastreo unidades</button>
        <button @click="ordenesLote" class="action-button">Órdenes en lote</button>
    </div>

    <!-- Table section -->
    <div class="table-container">
        <h2>Órdenes en proceso</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>ID <input v-model="filters.note_ident" @input="applyFilters" placeholder="Buscar ID" /></th>
                    <!--disabling date filters until able to implement date range as punctual datetime is not useful-->
                    <th>Fecha y hora de solicitud <!--<input type="datetime-local" v-model="filters.request_time"
                            @change="applyFilters" /> --></th>
                    <th>Cliente <input v-model="filters.client" @input="applyFilters" placeholder="Buscar Cliente" />
                    </th>
                    <!--
                    Disabling due to complexity. -->
                    <th>Origen
                        <!--<input v-model="filters.source" @input="applyFilters" placeholder="Buscar Origen" />--></th>
                    <th>Destino <!--<input v-model="filters.destination" @input="applyFilters"
                            placeholder="Buscar Destino" />--></th>
                    <th>Fecha y hora de inicio <!--<input type="datetime-local" v-model="filters.start_time"
                            @input="applyFilters" /> --></th>
                    <th>Fecha y hora de contacto <!-- <input type="datetime-local" v-model="filters.contact_time"
                            @input="applyFilters" /> -->
                    </th>
                    <!--disabling due to complexity-->
                    <th>Unidad 
                        <!--<input v-model="filters.unit_id" @input="applyFilters" placeholder="Buscar Unidad (# Económico)" />-->
                    </th>
                    <!--disabling due to complexity-->
                    <th>Operador <!--<input v-model="filters.operator_employee_ident" @input="applyFilters"
                            placeholder="Buscar Operador (# Económico)" /> --></th>
                    <th>Expediente <input v-model="filters.file_number" @input="applyFilters"
                            placeholder="Buscar Expediente" /></th>
                    <!--Disbling due to complexity-->
                    <th>Capturista <!--<input v-model="filters.data_entry_clerk" @input="applyFilters"
                            placeholder="Buscar Capturista (Apellido, Nombre)" /> --></th>
                    <th>Tipo<br>
                        <select v-model="filters.type" @change="applyFilters" multiple>
                            <option value=1> Auxilio víal</option>
                            <option value=2> Asistencia víal</option>
                            <option value=3> Montacargas</option>
                            <option value=4> Lote</option>
                        </select>
                    </th>
                    <th>Estatus <select v-model="filters.status" @change="applyFilters" multiple>
                            <option value=1> Activo</option>
                            <option value=5> Asignada</option>
                        </select>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in paginatedInProcessOrders" :key="index"
                    @click="viewNoteDetails(order.type, order.note_ident)">
                    <td>
                        <span
                            style="background-color: #FFC434; color: black; font-weight: bold; padding: 2px 5px; border-radius: 3px;">{{
                                order.note_ident }}</span>
                    </td> <!-- ID -->
                    <td><b>Fecha:</b>{{ order.request_time.substring(8, 10) }}/{{ order.request_time.substring(5, 7)
                        }}/{{ order.request_time.substring(0, 4) }} <b>Hora: </b>{{ order.request_time.substring(11, 16)
                        }}</td> <!-- Request time -->
                    <td>{{ order.client }}</td> <!-- Client -->
                    <td>{{ order.source.neighborhood }}, {{ order.source.city }}, {{ order.source.state }}</td>
                    <!-- Source -->
                    <td>{{ order.destination.neighborhood }}, {{ order.destination.city }}, {{ order.destination.state
                        }}</td> <!-- Destination -->
                    <td><b>Fecha: </b><span v-if="order.start_time"> {{ order.start_time.substring(8, 10) }}/{{
                        order.start_time.substring(5, 7) }}/{{ order.start_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>{{ order.start_time.substring(11, 16) }}</td> <!--Start time-->
                    <td><b>Fecha: </b><span v-if="order.contact_time"> {{ order.contact_time.substring(8, 10) }}/{{
                        order.contact_time.substring(5, 7) }}/{{ order.contact_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>{{ order.contact_time.substring(11, 16) }}</td> <!--Contact time-->
                    <td>{{ order.unit_id }}</td> <!-- Unit -->
                    <td><b>ID:</b>{{ order.operator_employee_ident }} </td> <!-- Operator -->
                    <td>{{ order.file_number }}</td> <!-- File number -->
                    <td>{{ order.data_entry_clerk }}</td> <!-- Data entry clerk -->
                    <td>{{ order.type }}</td> <!-- type -->
                    <td>
                        <span v-if="order.status === 'Activo'"
                            style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="order.status === 'Asignada'"
                            style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
                        <span v-else-if="order.status === 'Cancelado'"
                            style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
                        <span v-else-if="order.status === 'Programado'"
                            style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
                        <span v-else-if="order.status === 'Completado'"
                            style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
                        <span v-else>{{ order.status }}</span> <!-- Status -->
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevPage" :disabled="currentPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumber in totalInProcessPages" :key="pageNumber" @click="goToPage(pageNumber)"
                :class="{ active: pageNumber === currentPage }">{{ pageNumber }}</span>
            <button @click="nextPage" :disabled="currentPage >= totalInProcessPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
    </div>

    <!-- Table for scheduled orders -->
    <div class="table-container">
        <h2>Órdenes pendientes</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Fecha y hora de solicitud</th>
                    <th>Cliente</th>
                    <th>Origen</th>
                    <th>Destino</th>
                    <th>Fecha y hora de inicio</th>
                    <th>Fecha y hora de contacto</th>
                    <th>Unidad</th>
                    <th>Operador</th>
                    <th>Expediente</th>
                    <th>Capturista</th>
                    <th>Tipo</th>
                    <th>Estatus</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in paginatedScheduledOrders" :key="index"
                    @click="viewNoteDetails(order.type, order.note_ident)">
                    <td>
                        <span
                            style="background-color: #FFC434; color: black; font-weight: bold; padding: 2px 5px; border-radius: 3px;">{{
                            order.note_ident }}</span>
                    </td> <!-- ID -->
                    <td><b>Fecha:</b>{{ order.request_time.substring(8, 10) }}/{{ order.request_time.substring(5, 7)
                        }}/{{ order.request_time.substring(0, 4) }} <b>Hora: </b>{{ order.request_time.substring(11, 16)
                        }}</td> <!-- Request time -->
                    <td>{{ order.client }}</td> <!-- Client -->
                    <td>{{ order.source.neighborhood }}, {{ order.source.city }}, {{ order.source.state }}</td>
                    <!-- Source -->
                    <td>{{ order.destination.neighborhood }}, {{ order.destination.city }}, {{ order.destination.state
                        }}</td> <!-- Destination -->
                    <td><b>Fecha: </b><span v-if="order.start_time"> {{ order.start_time.substring(8, 10) }}/{{
                            order.start_time.substring(5, 7) }}/{{ order.start_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>{{ order.start_time.substring(11, 16) }}</td>
                    <!-- Start time -->
                    <td><b>Fecha: </b><span v-if="order.contact_time"> {{ order.contact_time.substring(8, 10) }}/{{
                            order.contact_time.substring(5, 7) }}/{{ order.contact_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>{{ order.contact_time.substring(11, 16) }}</td>
                    <!-- Contact time -->
                    <td>{{ order.unit_id }}</td> <!-- Unit -->
                    <td><b>ID:</b>{{ order.operator_employee_ident}} </td> <!-- Operator -->
                    <td>{{ order.file_number }}</td> <!-- File number -->
                    <td>{{ order.data_entry_clerk }}</td> <!-- Data entry clerk -->
                    <td>{{ order.type }}</td> <!-- type -->
                    <td>
                        <span v-if="order.status === 'Activo'"
                            style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="order.status === 'Asignada'"
                            style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
                        <span v-else-if="order.status === 'Cancelado'"
                            style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
                        <span v-else-if="order.status === 'Programado'"
                            style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
                        <span v-else-if="order.status === 'Completado'"
                            style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
                        <span v-else>{{ order.status }}</span> <!-- Status -->
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevScheduledPage" :disabled="currentScheduledPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumberScheduled in totalScheduledPages" :key="pageNumberScheduled"
                @click="goToScheduledPage(pageNumberScheduled)"
                :class="{ active: pageNumberScheduled === currentScheduledPage }">{{ pageNumberScheduled }}</span>
            <button @click="nextScheduledPage" :disabled="currentScheduledPage >= totalScheduledPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
    </div>
      </div>


      </div>
      <div
        class="tab-pane"
        :class="{ active: activeTab === 'tab2' }"
        v-if="activeTab === 'tab2'"
      >

      <div id="ContentForForklift">
        <div class="text-operations">
        <h2>Órdenes de trabajo</h2>
        <div class="thermometer" @mouseleave="hideStatusCountForklift">
            <div class="bar active" :style="{ width: (this.forkliftStatusCounts['Activo'] / (this.forklift_n_inProcessOrders+this.forklift_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountForklift('Activo', $event)"></div>
            <div class="bar cancelled" :style="{ width: (this.forkliftStatusCounts['Cancelado'] / (this.forklift_n_inProcessOrders+this.forklift_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountForklift('Cancelado', $event)"></div>
            <div class="bar scheduled" :style="{ width: (this.forkliftStatusCounts['Programado'] / (this.forklift_n_inProcessOrders+this.forklift_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountForklift('Programado', $event)"></div>
                <div class="bar assigned" :style="{ width: (this.forkliftStatusCounts['Asignada'] / (this.forklift_n_inProcessOrders+this.forklift_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountForklift('Asignada', $event)"></div>
            <div class="bar completed" :style="{ width: (this.forkliftStatusCounts['Completado'] / (this.forklift_n_inProcessOrders+this.forklift_n_scheduledOrders) * 100) + '%' }"
                @mouseover="showStatusCountForklift('Completado', $event)"></div>
        </div>
    </div>
    <p v-if="forkliftHoverStatusCount" :style="forkliftHoverStatusCountStyle" class="status-count-bubble">{{ forkliftHoverStatusCount }}
    </p>
    <div class="action-buttons">
        <!--IDK if global filtrs should remain here-->
        <button @click="globalfilters" class="action-button">Filtros globales</button>
        <button @click="statusUnits" class="action-button">Ver estatus de unidades</button>
        <button @click="pricesRequests" class="action-button">Ver cotizaciones</button>
        <button @click="newOrder" class="action-button">Nueva orden</button>
        <button @click="rastreoUnidades" class="action-button">Rastreo unidades</button>
        <button @click="ordenesLote" class="action-button">Órdenes en lote</button>
    </div>

    <!-- Table section -->
    <div class="table-container">
        <h2>Órdenes en proceso</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>ID <input v-model="forkliftFilters.note_ident" @input="applyFiltersForklift" placeholder="Buscar ID" /></th>
                    <!--disabling date filters until able to implement date range as punctual datetime is not useful-->
                    <th>Fecha y hora de solicitud <!--<input type="datetime-local" v-model="filters.request_time"
                            @change="applyFilters" /> --></th>
                    <th>Cliente <input v-model="forkliftFilters.client" @input="applyFiltersForklift" placeholder="Buscar Cliente" />
                    </th>
                    <!--
                    Disabling due to complexity. -->
                    <th>Origen
                        <!--<input v-model="filters.source" @input="applyFilters" placeholder="Buscar Origen" />--></th>

                    <th>Fecha y hora de inicio <!--<input type="datetime-local" v-model="filters.start_time"
                            @input="applyFilters" /> --></th>
                    <th>Fecha y hora de contacto <!-- <input type="datetime-local" v-model="filters.contact_time"
                            @input="applyFilters" /> -->
                    </th>
                    <!--disabling due to complexity-->
                    <th>Unidad 
                        <!--<input v-model="filters.unit_id" @input="applyFilters" placeholder="Buscar Unidad (# Económico)" />-->
                    </th>
                    <!--disabling due to complexity-->
                    <th>Operador <!--<input v-model="filters.operator_employee_ident" @input="applyFilters"
                            placeholder="Buscar Operador (# Económico)" /> --></th>
                    <th>Expediente <input v-model="forkliftFilters.file_number" @input="applyFiltersForklift"
                            placeholder="Buscar Expediente" /></th>
                    <!--Disbling due to complexity-->
                    <th>Capturista <!--<input v-model="filters.data_entry_clerk" @input="applyFilters"
                            placeholder="Buscar Capturista (Apellido, Nombre)" /> --></th>
                    <th>Estatus <select v-model="forkliftFilters.note_status" @change="applyFiltersForklift" multiple>
                            <option value=1> Activo</option>
                            <option value=5> Asignada</option>
                        </select>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in forkliftPaginatedInProcessOrders" :key="index"
                    @click="viewForkliftNoteDetails(order.note_ident)">
                    <td>
                        <span
                            style="background-color: #FFC434; color: black; font-weight: bold; padding: 2px 5px; border-radius: 3px;">{{
                                order.note_ident }}</span>
                    </td> <!-- ID -->
                    <td><b>Fecha:</b>{{ order.request_time.substring(8, 10) }}/{{ order.request_time.substring(5, 7)
                        }}/{{ order.request_time.substring(0, 4) }} <b>Hora: </b>{{ order.request_time.substring(11, 16)
                        }}</td> <!-- Request time -->
                    <td>{{ order.client }}</td> <!-- Client -->
                    <td>{{ order.source.neighborhood }}, {{ order.source.city }}, {{ order.source.state }}</td>
                    <!-- Source -->
                    <td><b>Fecha: </b><span v-if="order.start_time"> {{ order.start_time.substring(8, 10) }}/{{
                        order.start_time.substring(5, 7) }}/{{ order.start_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>{{ order.start_time.substring(11, 16) }}</td> <!--Start time-->
                    <td><b>Fecha: </b><span v-if="order.contact_time"> {{ order.contact_time.substring(8, 10) }}/{{
                        order.contact_time.substring(5, 7) }}/{{ order.contact_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>{{ order.contact_time.substring(11, 16) }}</td> <!--Contact time-->
                    <td>{{ order.unit_id }}</td> <!-- Unit -->
                    <td><b>ID:</b>{{ order.operator_employee_ident }} </td> <!-- Operator -->
                    <td>{{ order.file_number }}</td> <!-- File number -->
                    <td>{{ order.data_entry_clerk }}</td> <!-- Data entry clerk -->
                    <td>
                        <span v-if="order.note_status === 'Activo'"
                            style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="order.note_status === 'Asignada'"
                            style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
                        <span v-else-if="order.note_status === 'Cancelado'"
                            style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
                        <span v-else-if="order.note_status === 'Programado'"
                            style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
                        <span v-else-if="order.note_status === 'Completado'"
                            style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
                        <span v-else>{{ order.note_status }}</span> <!-- Status -->
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevPageForklift" :disabled="currentPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumber in forkliftTotalInProcessPages" :key="pageNumber" @click="goToPageForklift(pageNumber)"
                :class="{ active: pageNumber === currentPage }">{{ pageNumber }}</span>
            <button @click="nextPageForklift" :disabled="currentPage >= forkliftTotalInProcessPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
    </div>

    <!-- Table for scheduled orders -->
    <div class="table-container">
        <h2>Órdenes pendientes</h2>
        <table class="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Fecha y hora de solicitud</th>
                    <th>Cliente</th>
                    <th>Origen</th>
                    <th>Fecha y hora de inicio</th>
                    <th>Fecha y hora de contacto</th>
                    <th>Unidad</th>
                    <th>Operador</th>
                    <th>Expediente</th>
                    <th>Capturista</th>
                    <th>Estatus</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in forkliftPaginatedScheduledOrders" :key="index"
                    @click="viewForkliftNoteDetails(order.note_ident)">
                    <td>
                        <span
                            style="background-color: #FFC434; color: black; font-weight: bold; padding: 2px 5px; border-radius: 3px;">{{
                            order.note_ident }}</span>
                    </td> <!-- ID -->
                    <td><b>Fecha:</b>{{ order.request_time.substring(8, 10) }}/{{ order.request_time.substring(5, 7)
                        }}/{{ order.request_time.substring(0, 4) }} <b>Hora: </b>{{ order.request_time.substring(11, 16)
                        }}</td> <!-- Request time -->
                    <td>{{ order.client }}</td> <!-- Client -->
                    <td>{{ order.source.neighborhood }}, {{ order.source.city }}, {{ order.source.state }}</td>
                    <!-- Source -->
                    <td><b>Fecha: </b><span v-if="order.start_time"> {{ order.start_time.substring(8, 10) }}/{{
                            order.start_time.substring(5, 7) }}/{{ order.start_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>{{ order.start_time.substring(11, 16) }}</td>
                    <!-- Start time -->
                    <td><b>Fecha: </b><span v-if="order.contact_time"> {{ order.contact_time.substring(8, 10) }}/{{
                            order.contact_time.substring(5, 7) }}/{{ order.contact_time.substring(0, 4) }}</span> <span
                            v-else> <b> </b> </span> <b>Hora: </b>{{ order.contact_time.substring(11, 16) }}</td>
                    <!-- Contact time -->
                    <td>{{ order.unit_id }}</td> <!-- Unit -->
                    <td><b>ID:</b>{{ order.operator_employee_ident}} </td> <!-- Operator -->
                    <td>{{ order.file_number }}</td> <!-- File number -->
                    <td>{{ order.data_entry_clerk }}</td> <!-- Data entry clerk -->
                    <td>
                        <span v-if="order.note_status === 'Activo'"
                            style="background-color: green; color: white; padding: 2px 5px; border-radius: 3px;">Activo</span>
                        <span v-else-if="order.note_status === 'Asignada'"
                            style="background-color: #5eff00; color: black; padding: 2px 5px; border-radius: 3px;">Asignada</span>
                        <span v-else-if="order.note_status === 'Cancelado'"
                            style="background-color: red; color: white; padding: 2px 5px; border-radius: 3px;">Cancelado</span>
                        <span v-else-if="order.note_status === 'Programado'"
                            style="background-color: #8A2BE2; color: white; padding: 2px 5px; border-radius: 3px;">Programado</span>
                        <span v-else-if="order.note_status === 'Completado'"
                            style="background-color: blue; color: white; padding: 2px 5px; border-radius: 3px;">Completado</span>
                        <span v-else>{{ order.note_status }}</span> <!-- Status -->
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="pagination-container">
            <button @click="prevScheduledPageForklift" :disabled="currentScheduledPage === 1">
                <img src="@/assets/angulo-izquierdo.png" alt="Previous Page" />
            </button>
            <span v-for="pageNumberScheduled in forkliftTotalScheduledPages" :key="pageNumberScheduled"
                @click="goToScheduledPageForklift(pageNumberScheduled)"
                :class="{ active: pageNumberScheduled === currentScheduledPage }">{{ pageNumberScheduled }}</span>
            <button @click="nextScheduledPageForklift" :disabled="currentScheduledPage >= forkliftTotalScheduledPages">
                <img src="@/assets/angulo-derecho.png" alt="Next Page" />
            </button>
        </div>
    </div>
      </div>


      </div>
      
    </div>
  </div>



    
</template>

<style scoped>
.nav-bar-operations {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%; 
    height: 10vh; 
    background-color: #FFC434;
    z-index: 1000; 
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    margin: 0;
}
.logo-button {
    position: absolute; 
    height: 90%; 
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    margin: 0;
}
.text-operations {
    margin-top: 0vh; /* Adjust this value to ensure it's below the nav bar */
    left: 0;
    width: 100%;
    height: 5vh;
    border-bottom: 1px solid black;
    background-color: #ffffff;
    z-index: 999; /* Ensure it's above other content */
    font-size: 30px;
    display: flex;
    justify-content: space-between; /* Adjust spacing between elements */
    align-items: center; /* Align items vertically */
    overflow: hidden; /* Prevent text overflow */
}
.text-operations h2 {
    font-size: inherit; /* Adjust font size as needed */
    margin: 0;
    padding: 0;
    margin-left: 2vh;
}

.thermometer {
    width: 30%;
    height: 20px;
    background-color: #f2f2f2;
    border-radius: 5px;
    margin-top: 0px;
    display: flex; /* Use flexbox to align items */
    align-items: center; /* Center vertically */
    margin-right: 10px;
}
.bar {
    height: 100%;
    border-radius: 1px; /* Keep border-radius consistent */
}
.bar.active {
    background-color: green;
}
.bar.cancelled {
    background-color: red;
}
.bar.scheduled {
    background-color: #8A2BE2;
}
.bar.completed {
    background-color: blue;
}
.bar.assigned{
    background-color: rgb(238, 255, 6);
}
.action-buttons {
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
    align-items: center;
    margin-top: 1vh; 
    height: 3vh; 
}
.action-button {
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: #FFC434;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
    margin: 0 10px; 
}
.action-button:hover {
    background-color: #dba907;
}
.table-container {
    flex-grow: 1;
    overflow-x: auto;
    margin-top: 0;
    padding: 1vh;
}

.table {
    width: 100%;
    border-collapse: collapse;
    font-size: 0.6em; 
}

.table th, .table td {
    border-left: none;
    border-right: none;
    padding: 5px; 
    text-align: left;
}

.table tbody tr:first-child td {
    border-top: none;
}

.table tbody tr:hover td {
    background-color: #bdbdbd;
    color: rgb(0, 0, 0);
}

.table tbody tr:last-child td {
    border-bottom: none;
}

.table thead input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    margin: 0;
    border-radius: 5px;
    border-width: 1px;
}

.table th{
    border-left: none;
    border-right: none;
    padding: 10px;
    text-align: left
}

.table td {
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.pagination-container {
    justify-content: center;
    align-items: center;
    margin-top: 2vh;
    margin-bottom: 2vh;
}
.pagination-container button {
    padding: 5px 10px;
    border: 1px solid #000;
    background-color: #fff;
    cursor: pointer;
    margin: 0 10px;
    background-color: transparent; 
    border: none; 
}
.pagination-container button:disabled {
    color: #ccc;
    cursor: not-allowed;
}
.pagination-container span {
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
}

.pagination-container span.active {
    background-color: #FFC434;
}
.pagination-container button img {
    width: 25px; 
    height: auto; 
}
.tooltip {
  position: fixed;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  pointer-events: none; /* Ensure tooltip doesn't interfere with mouse events */
}
.status-count-bubble {
  background-color: rgba(255, 255, 255, 0.8); /* Transparent white background */
  padding: 5px 10px; /* Adjust padding as needed */
  border-radius: 5px; /* Add rounded corners */
}
@media (max-width: 768px) {
    .search-and-filter, .action-buttons {
        flex-direction: column;
        align-items: stretch;
    }
}

@media screen and (min-width: 768px) {
    .table th, .table td {
        width: 8%; 
    }
}

</style>

<script>
import axios from 'axios';
import Swal from 'sweetalert2';

export default {
    data() {
        return {
            activeTab: 'tab1', // Default active tab
            statusCounts: {
                Activo: 1, // Example hardcoded value
                Cancelado: 1, // Example hardcoded value
                Programado: 1, // Example hardcoded value
                Completado: 1, // Example hardcoded value
                Asignada: 1
            },
            hoverStatusCount: '',
            hoverStatusCountStyle: {
                position: 'absolute',
                top: 0,
                left: 0
            },
            filters: {
                status : ["1","5"],
                type: ["1","2","3","4"]
            },
            currentPage: 1,
            currentScheduledPage: 1,
            pageSize: 10,
            pageSizeScheduled: 20,
            scheduledOrders: [],
            inProcessOrders: [],
            originalOrders: [],
            n_inProcessOrders: 0,
            n_scheduledOrders: 0,
            /// Replicated logic for forklift notes
            forkliftStatusCounts: {
                Activo: 1, // Example hardcoded value
                Cancelado: 1, // Example hardcoded value
                Programado: 1, // Example hardcoded value
                Completado: 1, // Example hardcoded value
                Asignada: 1
            },
            forkliftHoverStatusCount: '',
            forkliftHoverStatusCountStyle: {
                position: 'absolute',
                top: 0,
                left: 0
            },
            forkliftFilters: {
                note_status : ["1","5"],
                type: ["1","2","3","4"]
            },
            forkliftCurrentPage: 1,
            forkliftCurrentScheduledPage: 1,
            forkliftPageSize: 10,
            forkliftPageSizeScheduled: 20,
            forkliftScheduledOrders: [],
            forkliftInProcessOrders: [],
            forkliftOriginalOrders: [],
            forklift_n_inProcessOrders: 0,
            forklift_n_scheduledOrders: 0,
        };
    },
    computed: {
        paginatedInProcessOrders() {
            //**Contains page of orders with status NE to "Programado" */
            return this.inProcessOrders
        },
        totalInProcessPages() {
            return Math.ceil(this.n_inProcessOrders / this.pageSize);
        },
        paginatedScheduledOrders() {
            //**Contains page of orders with status equal to "Programado" */
            return this.scheduledOrders
        },
        totalScheduledPages() {
            //** Computes the number of pages for the scheduled orders based on the number of scheduled notes / page size */
            return Math.ceil(this.statusCounts.Programado/this.pageSizeScheduled)
        },
        forkliftPaginatedInProcessOrders() {
            //**Contains page of orders with status NE to "Programado" */
            return this.forkliftInProcessOrders
        },
        forkliftTotalInProcessPages() {
            return Math.ceil(this.forklift_n_inProcessOrders / this.forkliftPageSize);
        },
        forkliftPaginatedScheduledOrders() {
            //**Contains page of orders with status equal to "Programado" */
            return this.forkliftScheduledOrders
        },
        forkliftTotalScheduledPages() {
            //** Computes the number of pages for the scheduled orders based on the number of scheduled notes / page size */
            return Math.ceil(this.forkliftStatusCounts.Programado/this.forkliftPageSizeScheduled)
        },
    },
    mounted() {
        this.fetchStatistics();
        this.fetchScheduled(); //called with default arguments 
        this.fetchInProcess(); //called with default arguments 
        this.fetchStatisticsForklift();
        this.fetchScheduledForklift();
        this.fetchInProcessForklift();
    },
    methods: {
        showStatusCount(status, event) {
            const count = this.statusCounts[status];
            this.hoverStatusCount = `${status}: ${count}`;
            this.hoverStatusCountStyle.top = `${event.clientY}px`;
            this.hoverStatusCountStyle.left = `${event.clientX}px`;
        },
        showStatusCountForklift(status, event) {
            const count = this.forkliftStatusCounts[status];
            this.forkliftHoverStatusCount = `${status}: ${count}`;
            this.forkliftHoverStatusCountStyle.top = `${event.clientY}px`;
            this.forkliftHoverStatusCountStyle.left = `${event.clientX}px`;
        },
        hideStatusCount() {
            this.hoverStatusCount = '';
        },
        hideStatusCountForklift() {
            this.forkliftHoverStatusCount = '';
        },
        viewNoteDetails(notetype, noteID) {
            // Calls EditNote component for aux or assistance notes
            Swal.fire({
                title: '¿Estás seguro?',
                text: `¿Quieres ver los detalles de la nota ${noteID}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, ver detalles',
                cancelButtonText: 'No, cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$router.push({ name: 'EditNote', params: { notetype, noteID } });
                }
            });
        },
        viewForkliftNoteDetails(noteID) {
            // Calls EditNote component for forklift notes
            const notetype = "Montacargas";
            Swal.fire({
                title: '¿Estás seguro?',
                text: `¿Quieres ver los detalles de la nota ${noteID}?`,
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sí, ver detalles',
                cancelButtonText: 'No, cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$router.push({ name: 'EditNote', params: { notetype, noteID } });
                }
            });
        },
        goToPage(pageNumber) {
            this.currentPage = pageNumber;
            const page = this.currentPage
            this.fetchInProcess(page);
        },
        goToPageForklift(pageNumber) {
            this.forkliftCurrentPage = pageNumber;
            const page = this.forkliftCurrentPage
            this.fetchInProcessForklift(page);
        },
        goToScheduledPage(pageNumberScheduled) {
            this.currentScheduledPage = pageNumberScheduled;
            const page = this.currentScheduledPage
            this.fetchScheduled(page);
        },
        goToScheduledPageForklift(pageNumberScheduled) {
            this.forkliftCurrentScheduledPage = pageNumberScheduled;
            const page = this.forkliftCurrentScheduledPage
            this.fetchScheduledForklift(page);
        },
        backMainPanel() {
            window.location.href = '/main-panel';
        },
        applyFilters() {
            this.fetchInProcess()
            this.fetchStatistics()
        },
        applyFiltersForklift() {
            this.fetchInProcessForklift()
            this.fetchStatisticsForklift()
        },
        pricesRequests() {
            window.location.href = '/prices-requests';
        },
        newOrder() {
            window.location.href = '/create-new-order';
        },
        rastreoUnidades() {
            window.location.href = '/main-map'
        },
        statusUnits() {
            window.location.href = '/ver-estatus-unidades'
        },
        globalfilters() {
            window.location.href = '/filtros-globales';
        },
        ordenesLote() {},
        async fetchStatistics(){
            let response 
            const apiDirectory = process.env.API_DIRECTORY;
            let request_filters = this.filters;
            // Request schedules status as or condition (Check view for details)
            request_filters.scheduled_orders = 1;
            try{
            response = await axios.post(`${apiDirectory}/get-services-metadata-statistics`,request_filters)
                const statusDict = {
                    1: "Activo",
                    2: "Cancelado",
                    3: "Programado",
                    4: "Completado",
                    5: "Asignada"
                };
                // Initialization to avoid undefined on bar
                let statusCounts = {
                    "Activo":0,
                    "Cancelado":0,
                    "Programado":0,
                    "Completado":0,
                    "Asignada":0
                };
                // Assign based on response 
                response.data.forEach(item => {
                    const status = statusDict[item.status]; // Get the name from the statusDict
                    const count = item.count;
                    statusCounts[status] = count;
                });
                // Returned from statistics endpoint 
                this.statusCounts = statusCounts;
            }
            catch(error){
                console.error("There was an error fetching the statistics",error)
            }
        },
        async fetchStatisticsForklift(){
            let response 
            const apiDirectory = process.env.API_DIRECTORY;
            let request_filters = this.forkliftFilters;
            // Request schedules status as or condition (Check view for details)
            request_filters.scheduled_orders = 1;
            try{
            response = await axios.post(`${apiDirectory}/get-forklift-metadata-statistics`,request_filters)
                const statusDict = {
                    1: "Activo",
                    2: "Cancelado",
                    3: "Programado",
                    4: "Completado",
                    5: "Asignada"
                };
                // Initialization to avoid undefined on bar
                let statusCounts = {
                    "Activo":0,
                    "Cancelado":0,
                    "Programado":0,
                    "Completado":0,
                    "Asignada":0
                };
                // Assign based on response 
                response.data.forEach(item => {
                    const status = statusDict[item.note_status]; // Get the name from the statusDict
                    const count = item.count;
                    statusCounts[status] = count;
                });
                // Returned from statistics endpoint 
                this.forkliftStatusCounts = statusCounts;
            }
            catch(error){
                console.error("There was an error fetching the statistics",error)
            }
        },
        async fetchScheduled(page = 1) {
            /**Fetches the scheduled notes */
            let response
            const apiDirectory = process.env.API_DIRECTORY;
            try {
                response = await axios.post(`${apiDirectory}/get-services-metadata-filtered?page=${page}&page_size=${this.pageSizeScheduled}`,{"status":[3]})
                const responseData = response.data.results;
                // Returned directly from paginator object
                responseData.forEach(order => {
                    if (!('contact_time' in order) || order.contact_time === null) {
                        order.contact_time = '';
                    }
                    if (!('start_time' in order) || order.start_time === null) {
                        order.start_time = '';
                    }
                    if (!('unit_id' in order) || order.unit_id === null) {
                        order.unit_id = 'N/A';
                    }
                    if (!('operator_first_name' in order) || order.operator_first_name === null) {
                        order.operator_first_name = 'N/A';
                    }
                    if (!('operator_employee_ident' in order) || order.operator_employee_ident === null) {
                        order.operator_employee_ident = 'N/A';
                    }
                });
                this.scheduledOrders = responseData
                this.n_scheduledOrders = response.data.count
                this.originalOrders = [...responseData]
            }
            catch (error) {
                console.error('Error fetching data from API:', error)
            }
        },
        async fetchScheduledForklift(page = 1) {
            /**Fetches the scheduled notes */
            let response
            const apiDirectory = process.env.API_DIRECTORY;
            try {
                response = await axios.post(`${apiDirectory}/get-forklift-metadata-filtered?page=${page}&page_size=${this.forkliftPageSizeScheduled}`,{"note_status":[3]})
                const responseData = response.data.results;
                // Returned directly from paginator object
                responseData.forEach(order => {
                    if (!('contact_time' in order) || order.contact_time === null) {
                        order.contact_time = '';
                    }
                    if (!('start_time' in order) || order.start_time === null) {
                        order.start_time = '';
                    }
                    if (!('unit_id' in order) || order.unit_id === null) {
                        order.unit_id = 'N/A';
                    }
                    if (!('operator_first_name' in order) || order.operator_first_name === null) {
                        order.operator_first_name = 'N/A';
                    }
                    if (!('operator_employee_ident' in order) || order.operator_employee_ident === null) {
                        order.operator_employee_ident = 'N/A';
                    }
                });
                this.forkliftScheduledOrders = responseData
                this.forklift_n_scheduledOrders = response.data.count
                this.forkliftOriginalOrders = [...responseData]
            }
            catch (error) {
                console.error('Error fetching data from API:', error)
            }
        },
        async fetchInProcess(page = 1) {
            /**Fetches the in process notes using the provided filters*/
            let response
            const apiDirectory = process.env.API_DIRECTORY;
            try {
                response = await axios.post(`${apiDirectory}/get-services-metadata-filtered?page=${page}&page_size=${this.pageSize}`,this.filters)
                const responseData = response.data.results;
                responseData.forEach(order => {
                    if (!('contact_time' in order) || order.contact_time === null) {
                        order.contact_time = '';
                    }
                    if (!('start_time' in order) || order.start_time === null) {
                        order.start_time = '';
                    }
                    if (!('unit_id' in order) || order.unit_id === null) {
                        order.unit_id = 'N/A';
                    }
                    if (!('operator_first_name' in order) || order.operator_first_name === null) {
                        order.operator_first_name = 'N/A';
                    }
                    if (!('operator_employee_ident' in order) || order.operator_employee_ident === null) {
                        order.operator_employee_ident = 'N/A';
                    }
                });
                this.inProcessOrders = responseData
                this.n_inProcessOrders = response.data.count
                this.originalOrders = [...responseData]
            }
            catch (error) {
                console.error('Error fetching data from API:', error)
            }
        },
        async fetchInProcessForklift(page = 1) {
            /**Fetches the in process notes using the provided filters*/
            let response
            const apiDirectory = process.env.API_DIRECTORY;
            try {
                response = await axios.post(`${apiDirectory}/get-forklift-metadata-filtered?page=${page}&page_size=${this.forkliftPageSize}`,this.forkliftFilters)
                const responseData = response.data.results;
                responseData.forEach(order => {
                    if (!('contact_time' in order) || order.contact_time === null) {
                        order.contact_time = '';
                    }
                    if (!('start_time' in order) || order.start_time === null) {
                        order.start_time = '';
                    }
                    if (!('unit_id' in order) || order.unit_id === null) {
                        order.unit_id = 'N/A';
                    }
                    if (!('operator_first_name' in order) || order.operator_first_name === null) {
                        order.operator_first_name = 'N/A';
                    }
                    if (!('operator_employee_ident' in order) || order.operator_employee_ident === null) {
                        order.operator_employee_ident = 'N/A';
                    }
                });
                this.forkliftInProcessOrders = responseData
                this.forklift_n_inProcessOrders = response.data.count
                this.forkliftOriginalOrders = [...responseData]
            }
            catch (error) {
                console.error('Error fetching data from API:', error)
            }
        },
        nextPage() {
            if (this.currentPage < this.totalInProcessPages) this.currentPage++;
            const page = this.currentPage
            this.fetchInProcess(page);
        },
        nextPageForklift() {
            if (this.forkliftCurrentPage < this.forkliftTotalInProcessPages) this.forkliftCurrentPage++;
            const page = this.forkliftCurrentPage
            this.fetchInProcessForklift(page);
        },
        prevPage() {
            if (this.currentPage > 1) this.currentPage--;
            const page = this.currentPage
            this.fetchInProcess(page);
        },
        prevPageForklift() {
            if (this.forkliftCurrentPage > 1) this.forkliftCurrentPage--;
            const page = this.forkliftCurrentPage
            this.fetchInProcessForklift(page);
        },
        nextScheduledPage() {
            if (this.currentScheduledPage < this.totalScheduledPages) this.currentScheduledPage++;
            const page = this.currentScheduledPage
            this.fetchScheduled(page);
        },
        nextScheduledPageForklift() {
            if (this.forkliftCurrentScheduledPage < this.forkliftTotalScheduledPages) this.forkliftCurrentScheduledPage++;
            const page = this.forkliftCurrentScheduledPage
            this.fetchScheduledForklift(page);
        },
        prevScheduledPage() {
            if (this.currentScheduledPage > 1) this.currentScheduledPage--;
            const page = this.currentScheduledPage
            this.fetchScheduled(page);
        },
        prevScheduledPageForklift() {
            if (this.forkliftCurrentScheduledPage > 1) this.forkliftCurrentScheduledPage--;
            const page = this.forkliftCurrentScheduledPage
            this.fetchScheduledForklift(page);
        },
    }
}
</script>